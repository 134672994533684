<template>
  <v-img
    :src="require('@/assets/quick-quote-logo.jpg')"
    class="mt-2 mb-2"
  />
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawerHeader',

    computed: {
      version: get('app/version'),
      app_name: get('app/app_name'),
    },
  }
</script>
